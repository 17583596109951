import familyMainSvg from '../../../../static/img/family-seo/family-main.svg'
import styled from 'styled-components'
import { Button } from '../../../atoms/Button'
import { Montserrat, size } from '../../../constants'
import { Title } from '../../../atoms/Title'

export const FamilyMainSvg = styled.img.attrs(() => ({
  src: familyMainSvg
}))`
  width: 588px;
  height: 588px;

  @media (max-width: ${size.md}) {
    width: 468px;
    height: 468px;
  }

  @media (max-width: ${size.xs}) {
    width: 350px;
    height: 350px;
  }

  @media (max-width: ${size.xxs}) {
    width: 234px;
    height: 234px;
  }
`

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Wrapper = styled.div`
  background-color: #e9f2fd;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: ${size.sm}) {
    display: block;

    ${Button.NewPrimary}, ${Button.NewOutline} {
      width: 100%;
      margin-bottom: 24px;
    }
  }

  @media (max-width: ${size.sm}) {
    display: none;
  }
`

export const BlockTitle = styled(Title.H1)`
  color: #000000;
  margin-bottom: 16px;
  padding-right: 32px;

  @media (max-width: ${size.sm}) {
    font-size: 24px;
    line-height: 32px;
  }

  @media (max-width: ${size.xs}) {
    font-size: 18px;
    line-height: 26px;
  }
`

export const MobileBlock = styled.div`
  display: none;

  @media (max-width: ${size.sm}) {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-bottom: 24px;
  }
`

export const MobileBlockFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const TextAsTitleH1 = styled.p`
  font-family: ${Montserrat};
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;

  @media (max-width: ${size.sm}) {
    font-size: 32px;
    line-height: 40px;
  }

  @media (max-width: ${size.xs}) {
    font-size: 24px;
    line-height: 32px;
  }
`

export const BlockContent = styled.div`
  padding: 72px 0;
  display: flex;
  flex-direction: row;
  gap: 24px;

  @media (max-width: ${size.lg}) {
    flex-direction: column;
  }

  @media (max-width: ${size.md}) {
    padding: 48px 0;
    flex-direction: column;
  }

  @media (max-width: ${size.sm}) {
    padding: 32px 0;
    flex-direction: column-reverse;
  }
`

export const MobileButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  & ${Button.NewPrimary} {
    width: 100%;
  }
`

export const InfoContainer = styled.div``
