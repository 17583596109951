import React from 'react'
import styled from 'styled-components'
import { ReactComponent as TitleIconSVG } from './img/titleIcon.svg'
import { size } from '../../constants'

const TitleIcon = styled(TitleIconSVG)``

export const SeoPriceForTitleBlock = styled(({ className }) => (
  <div className={className}>
    <TitleIcon />
    <p>от 2 000 ₽ за сессию</p>
  </div>
))`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 16px;

  @media (max-width: ${size.xs}) {
    gap: 8px;
  }

  & p {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;

    @media (max-width: ${size.sm}) {
      font-size: 24px;
      line-height: 32px;
    }

    @media (max-width: ${size.xs}) {
      font-size: 18px;
      line-height: 26px;
    }
  }
`
