export const familyCommentsData = [
  {
    img: 'person0.svg',
    imgAlt: 'Портрет девушки с розовыми волосами на зеленом фоне',
    name: 'Ангелина',
    age: '27 лет',
    title:
      'И я, и муж чувствовали себя максимально комфортно и уверенно на консультациях',
    desc: 'Я с уверенностью могу сказать, что в Ютоке только профессиональные специалисты! Обращалась год назад к психологу с волнующей меня темой - отношения с мужем. Мы быстро смогли решить волнующий меня вопрос. Все было четко, по делу, но в тоже время с большой заботой и поддержкой. В этом году мы с мужем решили также обратиться в семейную терапию, но уже к другому специалисту. И все прошло просто замечательно! Психолог по полочкам разобрала наши трудности в коммуникации друг с другом. Что важно, она не вставала ни на чью сторону. Поэтому и я, и муж чувствовали себя максимально комфортно и уверенно на консультациях. Нам еще много предстоит работы, но начало положено. Все благодаря Юток!'
  },
  {
    img: 'person7.svg',
    imgAlt: 'Портрет темноволосой девушки на желтом фоне',
    name: 'Алёна',
    age: '34 года',
    title: 'Очень бережный нам попался психолог, смог разговорить дочь',
    desc: 'Обращались в прошлом году с дочерью в этот сервис. Я хотела, чтобы она ходила на индивидуальные консультации, но в сервисе с подростками до 16 лет работают только в семейной терапии. Решили попробовать такой формат. Очень бережный нам попался психолог, смог разговорить дочь. Мы остались очень довольны'
  },
  {
    img: 'person1.svg',
    imgAlt: 'Портрет девушки в очках с черными волосами на красном фоне',
    name: 'Юлия',
    age: '35 лет',
    title: 'Оптимальные цены и специалисты с большим опытом',
    desc: 'Записались с мужем к семейному психологу на платформе YouTalk. Оптимальные цены и специалисты с большим опытом. Также нашла для себя психолога для индивидуальной работы. Ставлю 5 из 5'
  },
  {
    img: 'person4.svg',
    imgAlt: 'Портрет усатого мужчины с черными волосами на красном фоне',
    name: 'Денис',
    age: '26 лет',
    title: 'Все прошло хорошо',
    desc: 'Все прошло хорошо, спасибо!'
  },
  {
    img: 'person6.svg',
    imgAlt: 'Портрет женщины со светлыми волосами на синем фоне',
    name: 'Полина',
    age: '37 лет',
    title: 'Первая встреча с психологом прошла успешно',
    desc: 'Первая встреча с психологом прошла успешно. Все было хорошо, и мы продолжим работать над проблемами.'
  },
  {
    img: 'person5.svg',
    imgAlt:
      'Портрет бородатого кудрявого мужчины с черными волосами на светло-оранжевом фоне',
    name: 'Леонид',
    age: '24 года',
    title: 'Это действительно работает!',
    desc: 'Сначала был скептически настроен, но после первой консультации с психологом, найденным через YouTalk, изменил свое мнение. Это действительно работает!'
  }
]
