import React from 'react'
import {
  BlockContent,
  BlockTitle,
  ButtonsWrapper,
  FamilyMainSvg,
  ImageWrapper,
  InfoContainer,
  MobileBlock,
  MobileBlockFlex,
  MobileButtonWrapper,
  TextAsTitleH1,
  Wrapper
} from './FirstBlock.styles'
import { Button } from '../../../atoms/Button'
import { DescriptionWrapper } from '../Common.styles'
import { Event as GAEvent } from '../../../components/GA'
import { Grid } from '../../../atoms/Grid'
import { SeoPriceForTitleBlock } from '../../../atoms/SeoPriceForTitleBlock'
import { Text } from '../../../atoms/Text'

const FirstBlock = () => {
  return (
    <Wrapper>
      <section>
        <Grid>
          <BlockContent>
            <InfoContainer>
              <BlockTitle>
                Подбор и консультация семейного психолога онлайн&nbsp;— помощь
                с&nbsp;отношениями в&nbsp;семье
              </BlockTitle>
              <DescriptionWrapper>
                <Text.Large semiBold>
                  Консультация с&nbsp;семейным психологом поможет наладить
                  отношения в&nbsp;семье, найти общий язык, пережить трудности,
                  восстановить баланс или бережно завершить отношения.
                </Text.Large>
                <Text.Large semiBold>
                  Семейные психологи YouTalk — высококлассные специалисты,
                  которые помогут достичь ваших целей в&nbsp;отношениях.
                </Text.Large>
                <SeoPriceForTitleBlock />
              </DescriptionWrapper>
              <ButtonsWrapper>
                <Button.NewPrimary
                  href="/wizard/"
                  onClick={GAEvent.openSemeinyiPageFirstScreen}
                  size="large"
                  type="link"
                >
                  Подобрать психолога
                </Button.NewPrimary>
              </ButtonsWrapper>
            </InfoContainer>
            <ImageWrapper>
              <FamilyMainSvg />
            </ImageWrapper>
            <MobileBlock>
              <MobileBlockFlex>
                <TextAsTitleH1>Семейный психолог онлайн</TextAsTitleH1>
                <Text.Large semiBold>
                  Заполните анкету — мы подберем специалистов, которые помогут
                  решить проблемы в семейных отношениях
                </Text.Large>
              </MobileBlockFlex>
              <MobileButtonWrapper>
                <Button.NewPrimary
                  href="/wizard/"
                  onClick={GAEvent.openSemeinyiPageFirstScreen}
                  size="large"
                  type="link"
                >
                  Подобрать психолога
                </Button.NewPrimary>
              </MobileButtonWrapper>
            </MobileBlock>
          </BlockContent>
        </Grid>
      </section>
    </Wrapper>
  )
}
export default FirstBlock
